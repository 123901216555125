.FileList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
  list-style: none;
  padding: 0;
}
.FileList > div {
}
.FileList > div a {
  padding: 0.8rem;
  display: block;
  background-color: var(--filelist-link-background-color);
  cursor: pointer;
  border-radius: 1rem;
  filter: drop-shadow(0 0.25rem 0.5rem var(--shadow-color));
  outline-style: solid;
  outline-color: var(--selection-outline);
  outline-width: 0px;
  transition: outline-width 0.2s;
  position: relative;
}
.FileList > div a:hover {
  outline-width: 0.25rem;
}
.FileList > div a img {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
}
