.Breadcrumb {
  display: flex;
  flex-direction: row;
  margin-left: 0.5em;
  font-family: var(--condensed-font);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--breadcrumb-color);
}
.Breadcrumb > div {
  text-overflow: ellipsis;
  overflow: hidden;
}
.Breadcrumb > div::before {
  content: '/';
  color: var(--bg-text-color);
  margin: 0 0.5em 0 0;
  padding: 0;
}
.Breadcrumb > div a:visited {
  color: var(--bg-link-color);
}
.Breadcrumb > div a:hover {
  color: var(--bg-link-hover);
}
.Breadcrumb > div a {
  cursor: pointer;
  background: none;
  border: none;
  text-decoration: none;
  color: var(--bg-link-color);
  padding: 0;
  margin: 0 0.5em 0 0;
  transition: color 0.2s;
}
