.videoIcon {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(-50%) translateX(50%);
  opacity: 50%;
  width: 20%;
  transition: opacity 0.2s;
}
.videoIcon svg g path {
  fill: var(--photoelement-icon-fill);
}
a:hover .videoIcon {
  opacity: 75%;
}
