.InlineEditArea {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background: rgba(var(--browse-color), 0.1);
  border: inherit;
  border-radius: 0.5rem;
  padding: 0.5rem;
  outline: none;
  resize: none;
  overflow: hidden;
  width: 100%;
}

.InlineEditArea:focus {
  outline: 1px solid rgba(var(--browse-color), 0.5);
  border-color: rgba(var(--browse-color), 0.5);
}
