.AlbumList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 2rem;
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.AlbumList .AlbumItem {
  display: flex;
  flex: 1 1 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 25rem;
  margin: 0 0;
}
.AlbumList .AlbumItem > a:visited {
  color: var(--album-link-color-visited);
}
.AlbumList .AlbumItem > a {
  text-decoration: none;
  background-color: var(--album-link-background-color);
  color: var(--album-link-color-visited);
  display: flex;
  gap: 1rem;
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  padding: 1rem;
  border-radius: 1rem;
  filter: drop-shadow(0 0.25rem 0.5rem var(--shadow-color));
  margin: 0;
  outline-style: solid;
  outline-color: var(--selection-outline);
  outline-width: 0px;
  transition: outline-width 0.2s;
}
.AlbumList .AlbumItem > a:hover {
  outline-width: 0.25rem;
}

.AlbumList .AlbumItem .thumbnail {
  padding: 0;
  margin: 0;
  width: 10rem;
  min-width: 10rem;
}
.AlbumList .AlbumItem .thumbnail img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.AlbumList .AlbumItem .body {
  overflow: hidden;
}
.AlbumList .AlbumItem .body h1 {
  padding: 0;
  margin: 1rem 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--condensed-font);
  font-size: 1.5rem;
  color: var(--bg-text-color);
}
.AlbumList .AlbumItem .body .date {
  margin: 0;
  padding: 0.5rem 0 0 0;
}
.AlbumList .AlbumItem .body .desc {
  margin: 0;
  line-height: 1.5rem;
  padding: 0.5rem 1rem 0 0;
  max-height: 5rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  display: block;
  background: -webkit-linear-gradient(
    var(--album-link-color-visited) 75%,
    transparent
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.AlbumList .AlbumItem .body .desc p {
  margin-top: 0;
}
