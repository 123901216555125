.Browse {
  color: rgb(var(--browse-color));
}
.Browse header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.75rem;
  line-height: 3.5rem;
  color: var(--bg-text-color);
  text-shadow: 0 0.1rem 0.25rem var(--shadow-color);
  background: var(--main-bg-color);
}
.Browse header .logo {
  margin-top: 0.3rem;
}
.Browse header .logo img {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}
.Browse .pageBody {
  min-height: 90vh;
  background-color: var(--main-bg-color);
  padding: 0 5.5rem 2rem;
}
.Browse .pageBody > .album > .desc {
  margin-left: 20rem;
}
.Browse .pageBody .album .header {
  max-width: calc(36rem);
  margin: 0 0;
}
.Browse .pageBody .album .header .desc {
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}
.Browse .pageBody .album .header .desc p {
  margin-top: 0;
}
.Browse .editable {
  border: 1px solid red;
}
.Browse footer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.5rem;
  font-size: 1rem;
  color: var(--bg-text-color);
  padding: 0 1rem;
  line-height: 3rem;
}
.Browse footer a {
  color: var(--bg-link-color);
  transition: color 0.2s;
}
.Browse footer a:hover {
  color: var(--bg-link-hover);
}
@media only screen and (max-width: 600px) {
  .Browse .pageBody {
    padding: 1rem 0.75rem;
  }
}
