:root {
  --thumbnail-height: 5rem;
  --thumbnail-border: 0.5rem;
  --thumb-container-height: calc(
    var(--thumbnail-height) + (2 * var(--thumbnail-border))
  );
  --header-height: 3.7rem;
  --main-height: calc(
    100vh - var(--header-height) - var(--thumb-container-height)
  );
}
.PhotoElement {
  background-color: var(--photoelement-background-color);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
@supports (height: 100dvh) {
  :root {
    --main-height: calc(
      100dvh - var(--header-height) - var(--thumb-container-height)
    );
  }
  .PhotoElement {
    height: 100dvh;
  }
}

.PhotoElement .invisible-scrollbar {
  scrollbar-width: none;
}
.PhotoElement .invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
:root {
  --left-column-margin: 10rem;
  --column-margin: 10rem;
  --column-gutter: 1rem;
  --half-column-gutter: calc(0.5 * var(--column-gutter));
  --negative-column-gutter: calc(-1 * var(--column-gutter));
}
.PhotoElement .header {
  height: var(--header-height);
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.PhotoElement .header .headerTitle {
  position: relative;
  width: calc(50vw - var(--left-column-margin));
  max-width: calc(50vw - var(--left-column-margin));
  margin-right: var(--column-gutter);
  display: flex;
  align-items: flex-end;
  text-align: right;
  flex-direction: column;
  padding-top: 0.8rem;
}
.PhotoElement .header .headerTitle.solomente {
  margin-right: 0;
  align-items: normal;
  text-align: center;
  width: calc(100dvw - var(--column-margin));
  max-width: calc(100dvw - var(--column-margin));
}
.PhotoElement .header .headerTitle h1 {
  color: var(--photoelement-heading-color);
  width: 100%;
  max-width: 100%;
  font-size: 2.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  padding: 0;
  font-family: var(--condensed-font);
}
.PhotoElement .header .headerTitle h1.titlePlaceholder {
}
.PhotoElement .header .headerTitle h1.titleLong {
  margin-right: -1.1rem;
  margin-top: -0.3rem;
  padding-top: 0.3rem;
  width: calc(100% + 1.1rem);
  max-width: calc(100% + 1.1rem);
  position: absolute;
  opacity: 0;
  filter: drop-shadow(0 0.25rem 0.5rem var(--shadow-color));
  transition: all;
  padding-right: 1.1rem;
  border-radius: 0.5rem;
  transition: opacity 0.4s;
}
.PhotoElement .header .headerTitle:hover h1.titleLong {
  overflow: auto;
  white-space: initial;
  text-overflow: initial;
  background-color: var(--main-bg-color);
  height: auto;
  display: block;
  opacity: 1;
  z-index: 1;
}

.PhotoElement .header .headerTitle p.date {
  padding-top: 0;
  margin-top: 0;
}
.PhotoElement .header .headerElems {
  width: calc(50vw - var(--column-margin));
  max-width: calc(50vw - var(--column-margin));
  border-left: 2px solid var(--shadow-color);
  padding-left: var(--column-gutter);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.PhotoElement .header .headerElems p {
  margin: 0;
  padding: 0;
}
.PhotoElement .header .headerElems .description {
  position: relative;
  margin-top: 0.5rem;
  color: var(--bg-text-color);
}
.PhotoElement .header .headerElems .description .descriptionLong {
  position: absolute;
  z-index: 1;
  background-color: var(--main-bg-color);
  color: var(--album-link-color-visited);
  padding: var(--column-gutter);
  top: var(--negative-column-gutter);
  left: var(--negative-column-gutter);
  border-radius: 0.5rem;
  opacity: 0;
  overflow: hidden;
  max-height: var(--header-height);
  height: var(--header-height);
  transition:
    max-height 0.4s,
    opacity 0.4s,
    border-color 0.4s;
}
.PhotoElement .headerElems:has(> .description:hover) {
  border-color: var(--main-bg-color);
}
.PhotoElement .header .headerElems .description:hover .descriptionLong {
  max-height: 100dvh;
  height: auto;
  opacity: 1;
  filter: drop-shadow(0 0.25rem 0.5rem var(--shadow-color));
}
.PhotoElement .header .headerElems .descriptionPlaceholder {
  padding-right: var(--column-gutter);
  max-height: var(--header-height);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  background: linear-gradient(
    180deg,
    var(--album-link-color-visited) 0%,
    var(--album-link-color-visited) calc(var(--header-height) - 2rem),
    transparent calc(var(--header-height) - 0.5rem),
    transparent var(--header-height)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.PhotoElement .imageContainer {
  display: flex;
  height: var(--main-height);
  max-width: 100vw;
  flex-direction: row-reverse;
  align-content: center;
  align-items: center;
  overflow: hidden;
}
.PhotoElement .imageContainer .carousel {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: auto;
  -ms-overflow-style: none;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.PhotoElement .imageContainer.fullscreen .carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background: var(--photoelement-fullscreen-background-color);
  z-index: 1000;
}
.imageContainer.fullscreen ~ .fullscreenBtn {
  z-index: 1001;
}

.PhotoElement .imageContainer .carousel .carouselItem {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  text-align: center;
  margin: auto;
  position: relative;
  height: var(--main-height);
  max-width: 100vw;
  background: var(--photoelement-item-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.25rem;
}
.PhotoElement .imageContainer.fullscreen .carousel .carouselItem video,
.PhotoElement .imageContainer.fullscreen .carousel .carouselItem img,
.PhotoElement .imageContainer.fullscreen .carousel .carouselItem {
  max-height: 100vh;
  max-height: 100dvh;
  padding-right: 0;
  background: var(--photoelement-fullscreen-background-color);
}
.PhotoElement .imageContainer .carouselItem video,
.PhotoElement .imageContainer .carouselItem img {
  outline-width: 0;
  object-fit: contain;
  border: 0rem solid var(--filelist-link-background-color);
  border-radius: 1rem;
  filter: drop-shadow(0 0.25rem 0.5rem var(--shadow-color));
  transition: background-color 0.2s;
  max-width: 100%;
  max-height: calc(100dvh - 12rem);
}

.PhotoElement .imageContainer .exif {
  background: var(--exif-bg-color);
  color: var(--exif-text-color);
  width: 0rem;
  border-radius: 0 1rem 1rem 0;
  position: relative;
  filter: drop-shadow(0 0.25rem 0.5rem var(--shadow-color));
  margin-right: 2rem;
  transition:
    width 0.2s,
    color 0.2s,
    padding 0.2s;
}
.PhotoElement .imageContainer .exif:hover {
  width: 38rem;
}
.PhotoElement .imageContainer .exif .tag {
  position: absolute;
  background: var(--exif-bg-color);
  color: var(--exif-tab-text-color);
  top: 50%;
  right: -4.5rem;
  transform: translateY(-50%) rotate(270deg);
  white-space: nowrap;
  line-height: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0 0 1rem 1rem;
  transition: opacity 0.1s;
}
.PhotoElement .imageContainer .exif:hover .tag {
  opacity: 0;
}
.PhotoElement .exifInner {
  padding-left: 1rem;
  max-height: calc(var(--main-height) - 5rem);
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  overflow-y: auto;
  margin-left: -40rem;
  transition: margin-left 0.2s;
}
.PhotoElement .imageContainer .exif:hover .exifInner {
  margin-left: 0;
}
.PhotoElement .exifInner dt {
  text-transform: uppercase;
  font-size: 66%;
  font-weight: bold;
}
.PhotoElement .imageContainer .exif dd {
  color: var(--exif-header-color);
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

.PhotoElement .thumbContainer {
  height: var(--thumb-container-height);
  max-width: 100vw;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 0rem;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 0 50vw;
}
.PhotoElement .thumbContainer::-webkit-scrollbar {
  /* Chrome, Safari, Opera */
  display: none;
}
.PhotoElement .thumbContainer a {
  display: block;
  position: relative;
  margin-top: 0.25rem;
  transition: margin 0.3s;
}
.PhotoElement .thumbContainer a:hover,
.PhotoElement .thumbContainer a.sel {
  margin-left: 1rem;
  margin-right: 1rem;
}
.PhotoElement .thumbContainer a:hover {
  border-color: var(--photoelement-thumb-link-hover);
}
.PhotoElement .thumbContainer img {
  object-fit: cover;
  height: var(--thumbnail-height);
  width: var(--thumbnail-height);
}

.PhotoElement .fullscreenBtn {
  position: absolute;
  cursor: pointer;
  right: 3.75em;
  top: 1rem;
  margin-top: 0.1rem;
  opacity: 0.3;
}
.PhotoElement .fullscreenBtn:hover {
  opacity: 1;
}
.PhotoElement .closeBtn {
  position: absolute;
  cursor: pointer;
  left: 1rem;
  top: 1rem;
  opacity: 0.3;
}
.PhotoElement .downloadBtn {
  position: absolute;
  cursor: pointer;
  right: 6.5em;
  top: 1rem;
  opacity: 0.3;
}
.PhotoElement .downloadBtn:hover {
  opacity: 1;
}
.PhotoElement .closeBtn:hover {
  opacity: 1;
}
.PhotoElement .fullscreenBtn svg,
.PhotoElement .closeBtn svg,
.PhotoElement .downloadBtn svg {
  width: 1.5em;
  height: 1.5em;
}
.PhotoElement .downloadBtn svg g path,
.PhotoElement .closeBtn svg g path {
  fill: var(--photoelement-icon-fill);
}
.PhotoElement .fullscreenBtn svg g path {
  stroke: var(--photoelement-icon-fill);
}
